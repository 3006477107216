import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'

export default function Navba() {
    return (
        <div>
            <Navbar className='navbar' fixed="top" expand="md">
            <Container>
                <Navbar.Brand  href="#home" className='text-light' style={{'fontSize': 25}}> <img
                    src="images/logo.png"
                    width="100%"
                    height="50"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <Nav.Link href="#home" style={{'color':'#283a5ae6'}}>Home</Nav.Link>
                    <Nav.Link href="#about" style={{'color':'#283a5ae6'}}>About Us</Nav.Link>
                    <Nav.Link href="#services" style={{'color':'#283a5ae6'}}>Services</Nav.Link>
                    <Nav.Link href="#contacts" style={{'color':'#283a5ae6'}}>Contacts</Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>
        </div>
    )
}