import React from 'react'
import { Card, Carousel, Col, Container, Row } from 'react-bootstrap'

export default function Clients() {
    return (
        <div>
            <Container className="bg-light p-5 mt-5" fluid>
            <Container>
            <h2 className="text-center forcolor">Some of Our Clients</h2>

            <Carousel>
            <Carousel.Item interval={5000}>
            <Row className='mt-3'>
                    <Col sm={12} md={6} >
                        <Card className='clientCards' >
                        <Card.Body>
                            <Card.Title className='forcolor'>MotoSouk</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Motobike Industry</Card.Subtitle>
                            <Card.Text>
                            Moto Souk Limited provides readily accessible Bikes and Accessories, from Bajjaj.
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={6} >
                        <Card className='clientCards' >
                        <Card.Body>
                            <Card.Title className='forcolor'>HRA</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Medical Industry</Card.Subtitle>
                            <Card.Text>
                            We are committed to provide the best services from pediatric to adults of old age. 
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={6} >
                        <Card className='clientCards' >
                        <Card.Body>
                            <Card.Title className='forcolor'>Central Plumbing</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Repair industry</Card.Subtitle>
                            <Card.Text>
                            Our quality of work is a reflection of our business and as such we are proud to be part of an industry quality assured system.
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={6} >
                        <Card className='clientCards' >
                        <Card.Body>
                            <Card.Title className='forcolor'>Alena imports</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Imports and Ecports industry</Card.Subtitle>
                            <Card.Text>
                            The company's line of business includes the wholesale distribution of confectionery and related products.
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Carousel.Item>

            <Carousel.Item interval={5000} indicators={false}>
            <Row className='mt-3'>
                    <Col sm={12} md={6} >
                        <Card className='clientCards' >
                        <Card.Body>
                            <Card.Title className='forcolor'>Impala Auto Spares</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Spares Industry</Card.Subtitle>
                            <Card.Text>
                            Impala Auto Spares Limited (IAS) always provide the best quality products.
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={6} >
                        <Card className='clientCards' >
                        <Card.Body>
                            <Card.Title className='forcolor'>Beyond Dessert</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Food Industry</Card.Subtitle>
                            <Card.Text>
                            We go beyond just preparing dessert for our clients, we make it fresh and diffrent.
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={6} >
                        <Card className='clientCards' >
                        <Card.Body>
                            <Card.Title className='forcolor'>Automake Desel Services</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Oil industry</Card.Subtitle>
                            <Card.Text>
                            We provide genuine spare parts of your machines that will keep them running.
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={6} >
                        <Card className='clientCards' >
                        <Card.Body>
                            <Card.Title className='forcolor'>Pergas Group</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Gas industry</Card.Subtitle>
                            <Card.Text>
                            Pergas Company Ltd Est. 2016 is a group of five large manufacturers, as well as distributors in East Africa.
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Carousel.Item>
            </Carousel>
            </Container>
            </Container>
        </div>
    )
}
