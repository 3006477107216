import React from 'react'
import {Card, Col, Container, Row } from 'react-bootstrap'

export default function Services() {
    return (
        <>

            <Container className='mt-5 text-center' id='services'>
            <h2 className='forcolor'>What We Offer</h2>
            <h6>We pride Our selves in efficient softwares that are custom built</h6>
                <Row className='mt-4'>
                    <Col sm={12} md={4} >
                        <Card className='serviceCards'>
                        <Card.Body>
                            <i class="fas fa-mobile fa-3x" style={{'color': 'orange'}}></i>
                            <Card.Title>Mobile Apps</Card.Title>
                            <Card.Text>
                            Creation of software applications that are compatible with multiple mobile operating systems
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={4} >
                        <Card className='serviceCards'>
                        <Card.Body>
                            <i class="fas fa-cloud fa-3x" style={{'color': 'lightblue'}}></i>
                            <Card.Title>Cloud Services</Card.Title>
                            <Card.Text>
                            Rather than investing heavily in databases and software, you can rent our services and we take care of the inrastructure
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={4} >
                        <Card className='serviceCards'>
                        <Card.Body>
                            <i class="fab fa-chrome fa-3x" style={{'color': 'orangered'}}></i>
                            <Card.Title>Browser Apps</Card.Title>
                            <Card.Text>
                            Get applications that run on the web and you can use and monitor your busines from anyware
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col sm={12} md={4} >
                        <Card className='serviceCards'>
                        <Card.Body>
                            <i class="fas fa-desktop fa-3x" style={{'color': 'yellow'}}></i>
                            <Card.Title>Desk-top Apps</Card.Title>
                            <Card.Text>
                            manage your stocks with an app that can be run on a standalone computer to perform a specific task by an end-user.
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={4} >
                        <Card className='serviceCards'>
                        <Card.Body>
                            <i class="fab fa-ubuntu fa-3x" style={{'color': 'teal'}}></i>
                            <Card.Title>System Analysys</Card.Title>
                            <Card.Text>
                            We analys your business procedure to identify its goal create a system that will efficiently achieve them
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={4} >
                        <Card className='serviceCards'>
                        <Card.Body>
                            <i class="fas fa-exchange-alt fa-3x" style={{'color': 'orange'}}></i>
                            <Card.Title>App refactor</Card.Title>
                            <Card.Text>
                            We restructure existing syatem code to improve its performance without changing the code's intended functions
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col sm={12} md={4} >
                        <Card className='serviceCards'>
                        <Card.Body>
                            <i class="fas fa-vials fa-3x" style={{'color': ''}}></i>
                            <Card.Title>QA and Testing</Card.Title>
                            <Card.Text>
                            We use unique techniques to prevent issues with your software and to ensure great user experience for your customers.
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={4} >
                        <Card className='serviceCards'>
                        <Card.Body>
                            <i class="fas fa-phone-square-alt fa-3x" style={{'color': 'lightgreen'}}></i>
                            <Card.Title>Technical Surpport</Card.Title>
                            <Card.Text>
                            We offer a dedicated technical support for our clients whenever thier are issues on how the system operates
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={4} >
                        <Card className='serviceCards'>
                        <Card.Body>
                            <i class="fas fa-chess-pawn fa-3x" style={{'color': 'pink'}}></i>
                            <Card.Title>IT Strategy and Consulting</Card.Title>
                            <Card.Text>
                            We offer a comprehensive plan that outlines how technology should be used to meet IT and business goals
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Container className='mt-5 process' fluid>
            <h2 className="text-center text-light pt-4">Our working Process</h2>
                <Container className='pb-5'>
                    <Row>
                        <Col xs={6} lg={3}>
                            <Card className='processCards'>
                            <Card.Body>
                                <Card.Title>Identify Problem</Card.Title>
                                <Card.Subtitle className="mb-2" style={{'color': 'orangered'}}>Step One</Card.Subtitle>
                                <Card.Text>
                                Developing a detailed problem statement that includes the problem's effect on the company work flow.
                                </Card.Text>
                            </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={6} lg={3}>
                            <Card className='processCards'>
                            <Card.Body>
                                <Card.Title>Analysis</Card.Title>
                                <Card.Subtitle className="mb-2" style={{'color': 'teal'}}>Step Two</Card.Subtitle>
                                <Card.Text>
                            We analys your business procedure to identify its goal and create a system that will efficiently achieve them
                                
                                </Card.Text>
                            </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={6} lg={3}>
                            <Card className='processCards'>
                            <Card.Body>
                                <Card.Title>Build solution</Card.Title>
                                <Card.Subtitle className="mb-2" style={{'color': 'green'}}>Step Three</Card.Subtitle>
                                <Card.Text>
                                We create a software solution that is either mobile, web or desktop that adhere to the analysis done 
                                </Card.Text>
                            </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={6} lg={3}>
                            <Card className='processCards'>
                            <Card.Body>
                                <Card.Title>Deploy</Card.Title>
                                <Card.Subtitle className="mb-2" style={{'color': 'orange'}}>Step Four</Card.Subtitle>
                                <Card.Text>
                                Testing of the software is done to ensure verything runs smothly then it is pushed to production
                                </Card.Text>
                            </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid>
                <Container className='mt-5'>
                    <Row>
                        <Col className='text-end d-none d-md-block'>
                        <div>
                            <h2 className='forcolor'>What to Expect from Us</h2>
                            <p>When you choose Ap-Tech, you get access to an elite community of software developers who will develop quality systems for your business and offer training and support to your staff.</p>
                            <ul className='expect'>
                                <li> <i class="far fa-check-circle fa-1x" style={{'color': 'green'}} ></i>Dedicated support</li>
                                <li> <i class="far fa-check-circle fa-1x" style={{'color': 'green'}} ></i>realiable Systems</li>
                                <li> <i class="far fa-check-circle fa-1x" style={{'color': 'green'}} ></i>Training for the staff</li>
                                <li> <i class="far fa-check-circle fa-1x" style={{'color': 'green'}} ></i>Value for money</li>
                                <li> <i class="far fa-check-circle fa-1x" style={{'color': 'green'}} ></i>A follow up Onhow the system is functioning</li>
                            </ul>
                            <p>We dont just build systems, we build lasting relationships with our clients</p>
                            
                        </div>
                        </Col>
                        <Col>
                            <Row>
                            <h2 className='d-md-none text-center m-3 expText'>What to Expect from Us</h2>
                                <Col>
                                    <Card className='cardExpect'>
                                    <Card.Body>
                                        <Card.Title className='forcolor'> <i class="fas fa-phone-square-alt fa-1x" style={{'color': '#283a5ae6'}}></i> Dedicated Support</Card.Title>
                                        <Card.Text>
                                        We assign dedicated developers for your company who will be managing and supporting your system.                                       </Card.Text>
                                        
                                    </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card className='cardExpect'>
                                    <Card.Body>
                                        <Card.Title className='forcolor'> <i class="fab fa-ubuntu fa-1x" style={{'color': '#283a5ae6'}}></i> Reliable System</Card.Title>
                                        <Card.Text>
                                        Our systems are build with latest and most stable technology to ensure a smooth running of operations. 
                                        </Card.Text>
                                        
                                    </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className='mt-3'>
                                <Col>
                                    <Card className='cardExpect'>
                                    <Card.Body>
                                        <Card.Title className='forcolor'> <i class="fas fa-dumbbell fa-1x" style={{'color': '#283a5ae6'}}></i> Training</Card.Title>
                                        <Card.Text>
                                        We offer training to our clients on how to use the system, even when new staff come we train them too.
                                        </Card.Text>
                                    </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card className='cardExpect'>
                                    <Card.Body>
                                        <Card.Title className='forcolor'> <i class="fas fa-rainbow fa-1x" style={{'color': '#283a5ae6'}}></i> Value</Card.Title>
                                        <Card.Text>
                                        You get value for your money. Our systems will not delay or fail your business operations in any way.
                                        </Card.Text>
                                    </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
        
        </>
    )
}
