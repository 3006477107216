import {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import SweetAlert2 from 'react-sweetalert2';

export default function Contacts() {
    const [swalProps, setSwalProps] = useState({});

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [cell, setCell] = useState("")

    let navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault()
        const newMessage = {name, email, subject, message, cell}
        console.log(newMessage)

        try{
            fetch("https://aptech.co.ke/api/datafromwebsiteform.php", {
            method: "POST",
            headers: {"Content-type": "application/json"},
            body: JSON.stringify(newMessage)
        })
        .then(res => navigate("/"))
        .then(
            setName(''),
            setEmail(''),
            setSubject(''),
            setMessage(''),
            setCell(''),

            setSwalProps({
                show: true,
                title: 'Message delivered',
                text: 'We will contact you as soon as possible',
            })
            
        )
        }
        catch(err) {
            console.log(err.message)
        }
    }

    return (
        <div>
            <SweetAlert2 {...swalProps} />
            <Container className='mt-5' id='contacts'>
                <Row>
                <h2 class="text-center forcolor">Get In Touch</h2>
                    <Col sm={12} md={4} >
                        <Card className='contCards bg-light'>
                        <Card.Body>
                            <Card.Title> <i class="fas fa-map-marker-alt fa-2x" style={{'color': 'red'}}></i> Location</Card.Title>
                            <Card.Text>
                            Ngong Road, Adams Acade, Applwood 8th Floor
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={4} >
                        <Card className='contCards bg-light'>
                        <Card.Body>
                            <Card.Title> <i class="fas fa-phone-square fa-2x" style={{'color': 'green'}}></i> Phone</Card.Title>
                            <Card.Text>
                            <p>+254795501575</p>
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={4} >
                        <Card className='contCards bg-light'>
                        <Card.Body>
                            <Card.Title> <i class="fas fa-envelope-square fa-2x" style={{'color': 'orange'}}></i> Email</Card.Title>
                            <Card.Text>
                            <p>info@aptech.co.ke</p>
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className='mt-4'>
                <Form onSubmit={handleSubmit}>
                    <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control 
                        type="text" 
                        placeholder="Enter name" 
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                        type="email" 
                        placeholder="Enter email" 
                        required 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                            type="text" 
                            placeholder="Subject" 
                            required 
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            />
                        </Form.Group>
                        </Col>

                        <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                            type="number" 
                            placeholder="Cell" 
                            required 
                            value={cell}
                            onChange={(e) => setCell(e.target.value)}
                            />
                        </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                        as="textarea" 
                        placeholder="Enter Message" 
                        required rows={3} 
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        />
                    </Form.Group>
                    
                    <Button className='contBtn' variant="primary" type="submit">
                        Submit
                    </Button>

                    </Form>
                </Row>
            </Container>
        </div>
    )
}
