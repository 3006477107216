import React from 'react'
import Clients from './components/Clients'
import Contacts from './components/Contacts'
import Footer from './components/Footer'
import HeroSection from './components/HeroSection'
import MidSection from './components/MidSection'
import Navba from './components/Navba'
import Services from './components/Services'
import {BrowserRouter as Router} from 'react-router-dom';

export default function App() {
  return (
    <div>
      <Router>
        <Navba />
        <HeroSection />
        <MidSection />
        <Services />
        <Clients />
        <Contacts />
        <Footer />
      </Router>
    </div>
  )
}
