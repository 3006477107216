import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default function Footer() {
    return (
        <div>

        <Container fluid className='footer mt-5 text-white'>
            <Container className='py-5'>
                <Row>
                    <Col xs={6} md={3}>
                        <h3>AP Tech Kenya</h3>
                        <h6>Atomate and Achieve</h6>
                        <p>Talk to us for any kind of softwares.</p>
                        <p>Phone: +254795501575</p>
                        <p>Email: info@aptech.co.ke</p>
                        <a href='https://www.facebook.com/aptechkenya'>   
                            <i class="fab fa-facebook-square fa-3x footIcons" style={{'color': 'white'}}></i>
                        </a>
                        <a href='https://www.linkedin.com/company/40938757'>   
                            <i class="fab fa-linkedin fa-3x footIcons" style={{'color': 'white'}}></i>
                        </a>
                        {/* <i class="fab fa-twitter-square fa-3x footIcons" style={{'color': 'white'}}></i> */}
                        
                    </Col>
                    <Col xs={6} md={3}>
                        <ul>
                            <h5>Industries</h5>
                            <li>Healthcare</li>
                            <li>Atomotive</li>
                            <li>Insurance</li>
                            <li>Travel and Hoapitality</li>
                            <li>Agritech</li>
                        </ul>
                    </Col>
                    <Col xs={6} md={3}>
                        <ul>
                            <h5>Services</h5>
                            <li>Software development</li>
                            <li>Mobile development</li>
                            <li>Desktop development</li>
                            <li>QA and Testing</li>
                            <li>Technical support</li>
                            <li>Consulting</li>
                        </ul>
                    </Col>
                    <Col xs={6} md={3}>
                        <ul>
                            <h5>Expertise</h5>
                            <li>UI/UX Desighn </li>
                            <li>Digital Transformation</li>
                            <li>Big data</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <hr className='bg-light' />

            <Container className='text-center pb-1'>
                <h6>Copyright@2022Allrights Reserved</h6>
                <h6>Powered By Ap Tech Kenya</h6>
            </Container>
        </Container>
        </div>
    )
}
