import React from 'react'
import {Col, Container, Image, Row } from 'react-bootstrap'

export default function MidSection() {
    return (
        <div>
            <Container className='mt-5' id='about'>
                <h2 class="text-center forcolor">About Us</h2>
                <Row>
                    <Col className='d-none d-lg-block'>
                        <div>
                            <Image src="images/aptechpic.jpg" fluid />
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <h2 className='forcolor'>Exclusive company for your technology solutions</h2>
                            <p>AP Tech Kenya is an international software development company which is based in Kenya and deals with various projects ranging from cloud based desktop solutions, online browser based solutions and cross platform mobile applications.</p>
                            <ul>
                                <li>Our  <strong>Vision</strong> To be a company that inspires advancements in technology and use it to solve daily challenges. </li>
                                <li>Our  <strong>Mission</strong> is to listen to your needs and build our software in accordance to your requirements. We follow specifications until our customers have a satisfaction rate of 100%.</li>
                    
                            </ul>
                            <p>Having created a range of software in various industries, we assure you of quality and reliability in our solutions.</p>
                
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
