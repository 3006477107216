import React from 'react'
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap'

export default function HeroSection() {
    return (
    <> 
        <div className='heroImage'>
            <Image src="images/hero3.jpg" style={{ width: '100%' }} fluid />
        </div>

        <Container id='home'>
            <Row>
                <Col className='heroText'>
                    <h1>AP Tech Kenya</h1>
                    <h5>Automate and Achieve.</h5>
                </Col>
            </Row>
        </Container>

        <Container className='mt-5'>
            <Row>
                <Col sm={12} md={4}>
                    <Card className='heroCards'>
                    <Card.Body>
                        <Card.Title style={{'color': 'orange'}}> <i class="fab fa-cloudversify fa-2x"></i> Cloud</Card.Title>
                        <Card.Text>
                        You dont have to set up your own data centers, computing infrastracture we do it for you.
                        </Card.Text>
                        
                    </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={4}>
                    <Card className='heroCards'>
                    <Card.Body>
                        <Card.Title style={{'color': 'orange'}}> <i class="fas fa-mobile-alt fa-2x"></i> Mobile</Card.Title>
                        <Card.Text>
                        Have cross platform mobile applications and make it easy for your customers to download your app either on android or ios.
                        </Card.Text>
                        
                    </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={4}>
                    <Card className='heroCards'>
                    <Card.Body>
                        <Card.Title style={{'color': 'orange'}}> <i class="fab fa-chrome fa-2x"></i> Browser</Card.Title>
                        <Card.Text>
                        Access you system from anywhere in the world through a web based system and stay updated with whats going on.
                        </Card.Text>
                        
                    </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>


        <Container className='mt-5 text-center bg-light p-5 forcolor' fluid>
            <h3>Get an Efficient Software Tool For Your Organization</h3>
            <p>Get in touch with us today to automate and achieve your innovations</p> 
            <a href='#contacts'> <Button className='btn btn-warning'>Get in touch</Button> </a>
        </Container>
        
    </>
    )
}
